<template>
  <div class="login-container">
    <el-form
      :model="loginForm"
      :rules="loginRules"
      ref="loginFormRef"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">{{ t("login.title") }}</h3>
        <lang-select class="set-language" />
      </div>

      <el-form-item prop="email">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="email"
          v-model="loginForm.email"
          :placeholder="t('login.email_placeholder')"
          name="email"
          type="text"
          tabindex="1"
          auto-complete="on"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          ref="password"
          :key="passwordType"
          v-model="loginForm.password"
          :type="passwordType"
          :placeholder="t('login.password_placeholder')"
          name="password"
          tabindex="2"
          auto-complete="on"
          @v-on:native="checkCapslock"
          @blur="capsTooltip = false"
        ></el-input>
        <span class="show-pwd" @click="showPwd">
          <svg-icon
            :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
          />
        </span>
      </el-form-item>
      <el-form-item prop="captcha">
        <el-row type="flex">
          <el-col :span="16">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input
              ref="captcha"
              v-model="loginForm.captcha"
              :placeholder="t('login.captcha_placeholder')"
              name="captcha"
              type="text"
              tabindex="3"
              auto-complete="on"
              @keydown.enter="submitForm"
            >
            </el-input>
          </el-col>
          <el-col :span="8">
            <div class="captcha">
              <img
                class="captcha-img"
                :src="captchaImg"
                @click="refreshCaptcha"
              />
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width: 100%" @click="submitForm">
          {{ t("login.login") }}
        </el-button>
      </el-form-item>
      <span class="forget-password" v-if="false">{{ t("login.forget_password") }}</span>
    </el-form>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, ref, watch, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import LangSelect from "@/components/LangSelect";
import { getCaptcha } from "@/api/user";
import { ElMessage } from "element-plus";
export default {
  name: "login",
  components: { LangSelect },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();

    const redirect = ref("");
    const password = ref(null);

    const passwordType = ref("password");

    const captchaImg = ref("");

    const capsTooltip = ref(false)

    const checkCapslock = (e) => {
      const { key } = e
      capsTooltip.value = key && key.length === 1 && (key >= 'A' && key <="Z")
    }

    const showPwd = () => {
      if (passwordType.value === "password") {
        passwordType.value = "";
      } else {
        passwordType.value = "password";
      }
      nextTick(() => {
        password.value?.focus();
      });
    };

    watch(
      () => route.query.redirect,
      (newVal) => {
        redirect.value = newVal;
      },
      { immediate: true }
    );

    const loginForm = reactive({
      email: "",
      password: "",
      captcha: "",
      captcha_id: "",
    });

    const loginFormRef = ref(null);

    const loginRules = reactive({
      email: [
        {
          required: true,
          message: t("login.email_placeholder"),
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: t("login.email_placeholder"),
          trigger: "change",
        },
      ],
      captcha: [
        {
          required: true,
          message: t("login.captcha_placeholder"),
          trigger: "change",
        },
      ],
    });

    const LoginIn = (loginForm) => store.dispatch("user/Login", loginForm);

    const login = async () => {
      return await LoginIn(loginForm);
    };

    const submitForm = async () => {
      loginFormRef.value.validate(async (valid) => {
        if (valid) {
          const res = await login();
          console.log("login res", res);
          if (res) {
            ElMessage.success(t("login.success"));
            loginForm.captcha_id = "";
            router.push({ path: redirect.value || "/" });
          } else {
            ElMessage.error(t("login.fail"));
            // 登录失败时刷新验证码
            getCaptchaInfo();
          }
        }
      });
    };

    const getCaptchaInfo = async () => {
      const res = await getCaptcha({ id: loginForm.captcha_id });
      captchaImg.value = res.response.img;
      loginForm.captcha_id = res.response.id;
    };

    const refreshCaptcha = () => {
      getCaptchaInfo();
    };

    onMounted(() => {
      getCaptchaInfo();
    });
    return {
      loginForm,
      loginRules,
      loginFormRef,
      submitForm,
      showPwd,
      passwordType,
      t,
      captchaImg,
      refreshCaptcha,
      capsTooltip,
      checkCapslock,
    };
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}

$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }

    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .captcha-img {
    height: 52px;
    margin: 0px auto;
    display: block;
    max-width: 100%;
  }
  .captcha {
    background: #fff;
    border-radius: 5px;
    width: 100%;
  }
  .forget-password {
    float: right;
    color: #409eff;
    font-size: 14px;
  }
}
</style>